import React from "react";

function LegalPrivacy(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
<h2>1. Introduction</h2>
<p>This Privacy Policy explains how <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> collects, uses, and safeguards your personal information when you visit our website and use our services.</p>
<h2>2. Information Collection</h2>
<p>Personal Information: We collect personal information that you voluntarily provide, such as your name, email address, and other contact details when you register, contact us, or subscribe to our services.</p>
<p>Automated Information: When you visit our site, we automatically collect certain information like your IP address, browser type, and usage data. This may involve the use of cookies or similar tracking technologies.</p>
<h2>3. Use of Information</h2>
<p>The information we collect is used to provide and improve our services, respond to your requests, and communicate with you about investment opportunities and updates.</p>
<p>We may also use the information for internal analytical purposes, such as website traffic and user behavior analysis.</p>
<h2>4. Sharing of Information</h2>
<p><a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> does not sell your personal information. We may share your information with third-party service providers for operational purposes, under strict confidentiality agreements.</p>
<p>We may also disclose information if required by law or in response to legal processes.</p>
<h2>5. Data Security</h2>
<p>We employ reasonable security measures to protect your information from unauthorized access and use.</p>
<h2>6. Children’s Privacy</h2>
<p>Our website and services are not intended for children under the age of 13, and we do not knowingly collect information from children under this age.</p>
<h2>7. Changes to Privacy Policy</h2>
<p><a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> reserves the right to modify this Privacy Policy at any time. We encourage users to review it regularly.</p>
<h2>8. Contact Us</h2>
<p>For any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info@ingeneratelabs.com?subject=Privacy Policy">info@ingeneratelabs.com</a>.</p>
<h2>9. Your Rights</h2>
<p>You have the right to access, update, or delete your personal information that we hold. Please contact us to exercise these rights.</p>
    </div>
  );
}

export default LegalPrivacy;
