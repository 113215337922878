import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import FeaturesSection from "./../components/FeaturesSection";
import InvestSection from "./../components/InvestSection";
import ReadySection from "./../components/ReadySection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection2
        title="Where Visionaries Unite to Shape the Future"
        subtitle="Nexus of pioneering investors and ingenious minds dedicated to nurturing the next generation of groundbreaking startups"
        strapline=""
        size="lg"
        bgColor="bg-blue-900"
        bgImage="/images/bg1.svg"
        bgImageOpacity={0.6}
        textColor="text-white"
      />
      <FeaturesSection
        title="Innovating Beyond Boundaries"
        subtitle="Our focus? Transforming visionary ideas into market-leading realities. We're not just investors; we're architects of innovation."
        strapline="Our Unique Approach"
        size="md"
        bgColor="bg-white"
        bgImage="/images/bg2.svg"
        bgImageOpacity={0.2}
        textColor=""
      />
      <ReadySection
        title="Ready to make an impact?"
        subtitle="Ingenerate is your gateway to being part of something monumental. Connect with us and explore how your investment can redefine the landscape of technology and business. Join the Visionary Circle."
        strapline=""
        size="lg"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor="text-white"
      />
      <InvestSection
        title="Invest Today for a Better Tomorrow"
        subtitle="Join us and gain access to pre-vetted startups, share risks and rewards with like-minded investors, and immerse yourself in an environment of continuous learning."
        strapline="Why invest with INGENERATE?"
        size="md"
        bgColor="bg-white"
        bgImage="/images/bg3.svg"
        bgImageOpacity={0.2}
        textColor=""
      />
    </>
  );
}

export default IndexPage;
