import React from "react";
import Section from "./Section";
import { Link } from "./../util/router";

function Footer(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container">
        <div className="flex flex-col md:flex-row-reverse md:justify-between space-y-6 md:space-y-0 text-center md:text-left text-sm lg:text-base">
          <nav className="space-x-5 sm:space-x-7">
            {[
              { url: "/legal/terms-of-service", name: "Terms of Service" },
              { url: "/legal/privacy-policy", name: "Privacy Policy" },
            ].map((link, index) => (
              <Link
                to={link.url}
                className="font-medium text-gray-600 hover:text-gray-500"
                key={index}
              >
                {link.name}
              </Link>
            ))}
          </nav>
          <div className="text-gray-600">
            <span className="font-medium">INGENERATE LABS</span> ©
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;
