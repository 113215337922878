import React from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function ContentSection(props) {
  const posts = [
    {
      image: "/images/gg.svg",
      title: "GreetGrid",
      description: "A geolocation-based social network for dating. It employs an innovative marketing strategy based on Product-Led Growth (PLG). Currently, it is in the validation and market demand testing stage.",
      url: "https://docsend.com/view/xneyn4i9qeph68a2",
    },
    {
      image:  "/images/cr.svg",
      title: "CoachRun",
      description: "A sports tracker with in-depth data analysis designed for both professionals and enthusiasts of endurance sports. Developed with the support of Yuri Strofilov, the 2021 world marathon champion.",
      url: "https://docsend.com/view/jfuapc2vrad6ci7d",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-16 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
          {posts.map((post, index) => (
            <div
              className="flex flex-col items-center bg-white rounded-lg overflow-hidden shadow-sm"
              key={index}
            >
              <a href={post.url} className="block relative group" target="_blank" rel="noopener noreferrer">
                <div className="flex items-center justify-center absolute inset-0 bg-blue-700 bg-opacity-75 opacity-0 transition ease-out duration-150 group-hover:opacity-100">
                  <ArrowUpRightIcon className="text-white inline-block w-10 h-10" />
                </div>
                <img src={post.image} alt="" />
              </a>
              <div className="p-5 lg:p-6">
                <h4 className="font-bold text-lg sm:text-xl mb-2">
                  <a href={post.url} target="_blank" rel="noopener noreferrer" className="leading-7 text-gray-800 hover:text-gray-600">
                    {post.title}
                  </a>
                </h4>
                <p className="prose">{post.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}

export default ContentSection;
