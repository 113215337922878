import React from "react";

function LegalTerms(props) {
  return (
    <div className="prose prose-a:text-blue-600 max-w-none">
      <h2>1. Acceptance of Terms</h2>
      <p>By using <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a>, you agree to be bound by these Terms of Service and any amendments in the future. <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> reserves the right to update these terms at any time without notice.</p>
      <h2>2. Use of Service</h2>
      <p><a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> provides access to pre-vetted startup investments, opportunities for collaboration with like-minded investors, continuous learning through regular interactions with industry leaders, technical experts, and successful entrepreneurs, and strategic guidance for enhanced decision-making in the world of technology and business investments. Services are subject to change without notice.</p>
      <h2>3. User Obligations</h2>
      <p>You agree to use <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> services for lawful purposes only and in a manner consistent with any and all applicable local, state, national, and international laws and regulations.</p>
      <h2>4. Intellectual Property</h2>
      <p>All content on <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a>, including text, graphics, logos, and software, is the property of <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> or its content suppliers and protected by copyright and other intellectual property laws.</p>
      <h2>5. Limitation of Liability</h2>
      <p><a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> will not be liable for any damages or injury that accompany or result from your use of its services.</p>
      <h2>6. Privacy</h2>
      <p>Your use of <a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> is also governed by our Privacy Policy.</p>
      <h2>7. Termination</h2>
      <p><a href="https://ingeneratelabs.com" target="_blank" rel="noreferrer">ingeneratelabs.com</a> may terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      <h2>8. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of the State of Florida, United States.</p>
      <h2>9. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@ingeneratelabs.com?subject=Terms of Service">info@ingeneratelabs.com</a>.</p>
    </div>
  );
}

export default LegalTerms;
