import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection2(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="absolute inset-x-0 bottom-0 text-blue-700 text-opacity-50">
        <svg fill="currentColor" viewBox="0 0 500 80">
          <path d="M-0.84,34.03 C187.63,148.52 327.03,-4.44 499.72,40.95 L500.00,150.00 L0.00,150.00 Z" />
        </svg>
      </div>
      <div className="relative container">
        <div className="text-center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            strapline={props.strapline}
          />
        </div>
      </div>
    </Section>
  );
}

export default HeroSection2;
