import React from "react";
import Meta from "./../components/Meta";
import ContentSection from "./../components/ContentSection";
import { requireAuth } from "./../util/auth";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <ContentSection
        title="Active Opportunities"
        subtitle=""
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default requireAuth(DashboardPage);
